
import { OrgDepartmentEntityModel, OrgDepartmentQueryModel } from '@common-src/entity-model/org-department-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { post, del, get, download } from './request';
import { SimpleTreeModel } from '@common-src/model/simple-tree-model';

const URL_PATH = `${AUTH_BASE_REQUEST_PATH}/org`;

class OrgDepartmentService implements ICRUDQ<OrgDepartmentEntityModel, OrgDepartmentQueryModel> {
    async create(model: OrgDepartmentEntityModel): Promise<OrgDepartmentEntityModel> {
        const url = `${URL_PATH}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string): Promise<OrgDepartmentEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new OrgDepartmentEntityModel().toModel(res);
    }

    async update(model: OrgDepartmentEntityModel): Promise<OrgDepartmentEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: OrgDepartmentEntityModel): Promise<OrgDepartmentEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: OrgDepartmentQueryModel): Promise<any> {
        const params = query?.toService();
        const url = `${URL_PATH}/v2/tree`;
        const res = await post(url, params);
        return _.map(res, item => item = new OrgDepartmentEntityModel().toModel(item));
    }

    async getUserOrgTreeV2(query?: OrgDepartmentQueryModel): Promise<any> {
        const params = query?.toService();
        const url = `${URL_PATH}/v2/tree`;
        const res = await post(url, params);
        return res;
    }

    async downloadTemplate(): Promise<any> {
        const url = `${URL_PATH}/exportTemplate`;
        const res = await download(url, null, 'post');
        return res;
    }

    async exportTemplate(): Promise<any> {
        const url = `${URL_PATH}/export`;
        const res = await download(url, null, 'post');
        return res;
    }

    async importTemplate(file: any): Promise<any> {
        const url = `${URL_PATH}/import`;
        const formData = new FormData();
        formData.append('file', _.get(file, 'originFileObj'));
        const res = await post(url, formData, { headers: { 'content-type': 'multipart/form-data' }, timeout: 600000, responseType: 'blob' });
        return res;
    }

    async moveOrg(params: any): Promise<any> {
        const url = `${URL_PATH}/move`;
        const res = await post(url, params);
        return res;
    }

    async getOrgUserTreeHasUserV2(params: any, requestConfig?: any): Promise<any> {
        const url = `${URL_PATH}/v2/orgUserTree`;
        const res = await post(url, params, requestConfig);
        return _.map(res, item => {
            return new SimpleTreeModel().toModel(item, true);
        });
    }

    async syncFromCorp(): Promise<any> {
        const url = `${URL_PATH}/syncFromCorp`;
        const res = await post(url);
        return res;
    }

    async deleteOrgUser(orgId: string, userId: string): Promise<any> {
        const url = `${URL_PATH}/user/delete/${orgId}/${userId}`;
        const res = await post(url);
        return res;
    }

    async batchDeleteOrgUser(params: any): Promise<any> {
        const url = `${URL_PATH}/user/batch/delete`;
        const res = await post(url, params);
        return res;
    }
}

export default new OrgDepartmentService();
