import { FormControl, FormControlType, FormControlOptionModel } from '@common-src/model/form-control';
import { QueryModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { BaseEntityModel } from '@common-src/model/base-model';
import { OrgTreeType } from '@common-src/model/enum';
import { dateFormat } from '@common-src/filter/date-format';
import UserService from '@common-src/service/user-v2';
import CommonService from '@common-src/service/common';

export function formatOrgTreeType(type: string) {
    switch (type) {
        case OrgTreeType.CORP:
            return '企业';
        case OrgTreeType.DEPT:
            return '部门';
        case OrgTreeType.SUBCOMPANY:
            return '子公司';
        default:
            return '';
    }
}

export class OrgDepartmentEntityModel extends BaseEntityModel {
    constructor(type?: string) {
        super();
        this.type = type || null;
    }
    @FormControl({
        label: '部门名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlOptionModel)
    name: string = undefined;

    @FormControl({
        label: '部门标识符',
        type: FormControlType.TEXT,
        required: true,
        readonly: true
    } as FormControlOptionModel)
    identifier: string = undefined;

    @FormControl({
        label: '上级部门',
        type: FormControlType.TREE_SELECT,
        optionsPromise: new UserService().getUserOrgTreeV2,
        required: true,
        invisibleFunction: (model) => model.type === 'project'
    } as FormControlOptionModel)
    parentId: string = undefined;

    @FormControl({
        label: '上级部门',
        type: FormControlType.TREE_SELECT,
        optionsPromise: new UserService().getUserOrgTreeV2,
        invisibleFunction: (model) => model.type !== 'project'
    } as FormControlOptionModel)
    projectParentId: string = undefined;

    @FormControl({
        label: '部门类型',
        type: FormControlType.SELECT,
        options: [
            { name: '子公司', value: OrgTreeType.SUBCOMPANY },
            { name: '部门', value: OrgTreeType.DEPT }
        ],
        required: true
    } as FormControlOptionModel)
    orgType: string = undefined;

    @FormControl({
        label: '负责人',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getPersonList,
        mode: 'multiple'
    } as FormControlOptionModel)
    userIds: any = undefined;

    children: any = null;
    userNum: any = null;
    updateUserName: any = null;
    updateTime: any = null;
    leaders: any = null;
    type: any = null;

    static getTableColumns() {
        return [
            {
                title: '部门名称',
                dataIndex: 'name'
            },
            {
                title: '类型',
                dataIndex: 'orgType',
                width: 120,
                customRender: (text: string) => {
                    return formatOrgTreeType(text);
                }
            },
            {
                title: '人数',
                dataIndex: 'userNum',
                width: 80
            },
            {
                title: '负责人',
                dataIndex: 'leaders',
                width: 500,
                scopedSlots: { customRender: 'leaders' }
            },
            {
                title: '更新人',
                dataIndex: 'updateUserName',
                width: 150,
                scopedSlots: { customRender: 'updateUserName' }
            },
            {
                title: '更新时间',
                dataIndex: 'updateTime',
                width: 180,
                customRender: (text: string) => {
                    return dateFormat(text);
                }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 150
            }
        ];
    }

    toModel(json: any) {
        super.toModel(json);
        if (json && json.children && json.children.length > 0) {
            this.children = _.map(json.children, item => {
                item.parentId = this.id;
                const child: OrgDepartmentEntityModel = new OrgDepartmentEntityModel().toModel(item);
                return child;
            });
        } else {
            this.children = null;
        }
        this.userIds = json.userIds || undefined;
        this.parentId = json.parentId;
        this.projectParentId = json.parentId;
        return this;
    }

    toService() {
        const data: any = super.toService();
        if (this.type) {
            data.parentId = data.projectParentId;
        }
        delete data.updateUserName;
        delete data.userNum;
        delete data.children;
        delete data.updateTime;
        delete data.projectParentId;
        delete data.type;
        delete data.leaders;
        delete data.userIds;
        const params = {
            org: data,
            userIds: this.userIds
        };
        return params;
    }
}

export class OrgDepartmentQueryModel extends QueryModel {
    @QueryControl({
        placeholder: '搜索部门名称',
        type: QueryControlType.TEXT,
        span: 12
    })
    orgName: string = undefined;

    includeUserInfo: string = 'true';
}
